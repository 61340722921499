export default [
  {
    path: '/companyanalysis',
    name: 'Company Analysis',
    component: () => import('@/views/extensions/CompanyAnalysis/Tabs.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  // {
  //   path: '/tabs',
  //   name: 'tabs',
  //   component: () => import('@/views/extensions/CompanyAnalysis/Tabs mit Radio.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/pages/pricing/checkout.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/financials',
    name: 'Financials',
    component: () => import('@/views/extensions/financials/financials.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/growth',
    name: 'Growth',
    component: () => import('@/views/extensions/growth/Growth.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/profitability',
    name: 'Profitability',
    component: () => import('@/views/extensions/profitability/profitability.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/value',
    name: 'Valuation',
    component: () => import('@/views/extensions/valuation/valuation.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/screener',
    name: 'Screener',
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/buffett-screener',
    name: 'Buffett-Screener',
    component: () => import('@/views/extensions/screener/BuffettScreener/screener.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/lynch-screener',
    name: 'Lynch-Screener',
    component: () => import('@/views/extensions/screener/LynchScreener/screener.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/graham-screener',
    name: 'Graham-Screener',
    component: () => import('@/views/extensions/screener/GrahamScreener/screener.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/Custom-screener',
    name: 'Custom-Screener',
    component: () => import('@/views/extensions/screener/CustomScreener/screener.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/portfolio-builder',
    name: 'Portfolio-Builder',
    component: () => import('@/views/extensions/portfoliobuilder/portfoliobuilder.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/sentiment-analysis',
    name: 'Sentiment-Analysis',
    component: () => import('@/views/extensions/sentimentanalysis/sentimentanalysis.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('@/views/extensions/portfolio/PortfolioTabs.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: () => import('@/views/extensions/Watchlist/Watchlist.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/WelcomePage/KnowledgeBase.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/extensions/Feedback/Feedback.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  }
]
