import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
  state: {
    user: {},
    FreeTrial: 0,
    PaymentPlan: null,
    CurrentCompdata: {},
    currentPortfolio: [],
    BuffettScreen: [],
    CustomScreen: [],
    Watchlist: [],
    Watch:[],
    MarketData: [],
    PortfolioValues: [],
    DiscountRate: 0,
    PurchaseOption: 0,
    DiscountRateRealistic: 0,
    DiscountRatePessimistic: 0,
    YearsOptimistic: 10, // Add this property
    YearsRealistic: 8, // Add this property
    YearsPessimistic: 7, 
    BackTestDate: null,
    BackTestValues: [],

  },
  compname:'',
  mutations: {
    incrementFreeTrial(state) {
      state.FreeTrial++
    },
    setUser(state, payload) {
      state.user = payload; 
    },
    setPaymentPlan(state, payload) {
      state.PaymentPlan = payload; 
    },
    setFreeTrial(state, payload) {
      state.FreeTrial = payload; 
    },
    setCurrentCompdata(state, payload) {
      state.CurrentCompdata = payload; 
    },
    setCurrentPortfolio(state, payload) {
      state.currentPortfolio = payload;
    },
    setBuffettScreen(state,payload) {
      state.BuffettScreen = payload
    },
    setCustomScreen(state,payload) {
      state.CustomScreen = payload
    },
    setWatchlist(state,payload) {
      state.Watchlist = payload
    },
    setWatch(state,payload) {
      state.Watch = payload
    },
    setDiscountRate(state,payload) {
      state.DiscountRate = payload
    },
    setDiscountRateRealistic(state, payload) {
      state.DiscountRateRealistic = payload;
    },
    setYearsOptimistic(state, payload) {
      state.YearsOptimistic = payload;
    },

    setYearsRealistic(state, payload) {
      state.YearsRealistic = payload;
    },

    setYearsPessimistic(state, payload) {
      state.YearsPessimistic = payload;
    },

    setDiscountRatePessimistic(state, payload) {
      state.DiscountRatePessimistic = payload;
    },
    setPortfolioValues(state,payload) {
      state.PortfolioValues = payload;
    },
    setMarketData(state,payload) {
      state.MarketData = payload;
    },
    setPurchaseOption(state, payload) {
      state.PurchaseOption = payload;
      localStorage.setItem('purchaseOption', JSON.stringify(payload))
    },
    setBackTestDate(state, payload) {
      state.BackTestDate = payload;
    },
    setBackTestValues(state, payload) {
      state.BackTestValues = payload;
    },
  },
  actions: {
    async setUser(state,payload){
      state.commit('setUser', payload);
    },
    async setPaymentPlan(state,payload){
      state.commit('setPaymentPlan', payload);
    },
    async setCurrentCompdata(state,payload){
      state.commit('setCurrentCompdata', payload);
    },
    async setFreeTrial(state,payload){
      state.commit('setFreeTrial', payload);
    },

    async setYearsOptimistic({ commit }, payload) {
      commit('setYearsOptimistic', payload);
    },

    async setYearsRealistic({ commit }, payload) {
      commit('setYearsRealistic', payload);
    },

    async setYearsPessimistic({ commit }, payload) {
      commit('setYearsPessimistic', payload);
    },
    async setCurrentPortfolio(state, payload) {
      state.commit('setCurrentPortfolio', payload);
    },
    async setDiscountRateRealistic({ commit }, payload) {
      commit('setDiscountRateRealistic', payload);
    },

    async setDiscountRatePessimistic({ commit }, payload) {
      commit('setDiscountRatePessimistic', payload);
    },
    async setBuffettScreen(state, payload) {
      state.commit('setBuffettScreen', payload);
    },
    async setCustomScreen(state, payload) {
      state.commit('setCustomScreen', payload);
    },
    async setWatchlist(state, payload) {
      state.commit('setWatchlist', payload)
    },
    async setWatch(state, payload) {
      state.commit('setWatch', payload)
    },
    async setDiscountRate(state, payload) {
      state.commit('setDiscountRate', payload)
    },
    async setPortfolioValues(state,payload) {
      state.commit('setPortfolioValues',payload)
    },
    async setMarketData(state,payload) {
      state.commit('setMarketData',payload)
    },
    async setPurchaseOption({ commit }, payload) {
      commit('setPurchaseOption', payload);
      return Promise.resolve(); // Return a resolved Promise
    },
    async setBackTestDate({ commit }, payload) {
      commit('setBackTestDate', payload);
      return Promise.resolve(); // Return a resolved Promise
    },
    async setBackTestValues({ commit }, payload) {
      commit('setBackTestValues', payload);
      return Promise.resolve(); // Return a resolved Promise
    },
  },
  getters: {
    getUser:(state) => state.user,
    getPaymentPlan:(state) => state.PaymentPlan,
    getFreeTrial: (state) => state.FreeTrial,
    getDiscountRateRealistic: (state) => state.DiscountRateRealistic,
    getDiscountRatePessimistic: (state) => state.DiscountRatePessimistic,
    getCurrentCompdata:(state) => state.CurrentCompdata,
    getCurrentPortfolio:(state) => state.currentPortfolio,
    getBuffettScreen:(state) => state.BuffettScreen,
    getYearsOptimistic: (state) => state.YearsOptimistic,
    getYearsRealistic: (state) => state.YearsRealistic,
    getYearsPessimistic: (state) => state.YearsPessimistic,
    getCustomScreen:(state) => state.CustomScreen,
    getWatchlist:(state) => state.Watchlist,
    getWatch:(state) => state.Watch,
    getDiscountRate:(state) =>state.DiscountRate,
    getPortfolioValues:(state) =>state.PortfolioValues,
    getMarketData:(state) =>state.MarketData,
    getPurchaseOption:(state) => state.PurchaseOption,
    getBackTestDate: (state) => state.BackTestDate,
    getBackTestValues: (state) => state.BackTestValues,
 }
})
